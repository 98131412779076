import axios from 'axios'
import { createApp as createVueApp } from 'vue'
import emitter from 'tiny-emitter/instance'

import Modal from './VueComponents/modal.vue'
import CartTimer from './VueComponents/cart-timer.vue'
import LoadingSpinner from './VueComponents/loading-spinner.vue'
import DoorSalesControls from './VueComponents/DoorSales/door-sales-controls.vue'

import { formatMoney } from 'accounting-js'
import { AxiosRedirectIntercepted } from './errors'

const setAxiosHeaderToIndicateRequestIsAnAjaxOne = () => {
    // set this header so we can detect on the server if this is an ajax request
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
}

const setAxiosToInterceptRedirectsAndThrow = () => {
    axios.interceptors.response.use(response => {
        const redirectUrl = response && response.data && response.data.redirect
        if (redirectUrl) {
            window.location = redirectUrl
            // we throw an error here to break through any promise then's
            // straight to the catch
            throw new AxiosRedirectIntercepted()
        }

        return response
    })
}

const setAxiosHeaderToAspAntiForgeryToken = () => {
    axios.interceptors.request.use(config => {
        if(config.method && config.method.toLowerCase() == "post")
        {
            const aspnetAntiForgeryToken = document.getElementsByName(
                '__RequestVerificationToken',
            )

            if (aspnetAntiForgeryToken.length === 0) {
                throw new Error('Could not find anti forgery token: input[name=__RequestVerificationToken] was not found');
            } else {
                config.headers['RequestVerificationToken'] = aspnetAntiForgeryToken[0].value
            }
        }
        return config
    })
}

function setupAxios() {
    setAxiosHeaderToIndicateRequestIsAnAjaxOne();
    setAxiosToInterceptRedirectsAndThrow();
    setAxiosHeaderToAspAntiForgeryToken();
}

export function currencyFormat(value) {
    if (value === '-') return value;
    return formatMoney(value, '$', 2, ',', ',');
}

const addGlobalComponentsToVue = (app) => {
    app.component('modal', Modal)
    app.component('cart-timer', CartTimer)
    app.component('door-sales-controls', DoorSalesControls)
    app.component('loading-spinner', LoadingSpinner)
}

const createEventBus = () => {
    const EventBus = {
        $on: (...args) => emitter.on(...args),
        $once: (...args) => emitter.once(...args),
        $off: (...args) => emitter.off(...args),
        $emit: (...args) => emitter.emit(...args),
    };
    window.EventBus = EventBus;
    return EventBus;
};

export const EventBus = createEventBus()

export function createApp(component) {
    setupAxios();
    const app = createVueApp(component);
    addGlobalComponentsToVue(app);
    return app;
}